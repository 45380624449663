import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, CssBaseline, TextField, Button, Typography, Box, Avatar, Grid } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuth } from '../useAuth';

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [tfaToken, setTfaToken] = useState('');
  const [showTfaCode, setShowTfaCode] = useState(false);
  const [tfaCode, setTfaCode] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tfa = params.get("tfa") === "true"; // Convert to boolean
    setShowTfaCode(tfa)
  }, []);

  const { isAuthenticated, setIsAuthenticated } = useAuth();


  const handleLogin = async () => {
    try {
    const response = await axios.post('https://api.slace.com/core/auth/login', { username, password });
      switch (response.data.token_type) {
        case "tfa":
          console.log("2FA flow")
          setTfaToken(response.data.access_token);
          setShowTfaCode(true);
          navigate('/login?tfa=true');
          break;
          default:
            console.log("NON 2FA")
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem("expiresAt", new Date((new Date()).getTime() +  response.data.expires_in * 1000).toISOString());
            setIsAuthenticated(true);
            navigate('/');
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed!');
    }
  };

  const handleTfaCode = async () => {
    const response = await axios.post('https://api.slace.com/core/auth/tfa-code', { code: tfaCode, token: tfaToken,
      trusted_device: true });

    if (response.data.access_token) {
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem("expiresAt", new Date((new Date()).getTime() +  response.data.expires_in * 1000).toISOString());
      setIsAuthenticated(true);
      setShowTfaCode(false);
      navigate('/');
    }
  };


if (showTfaCode) {return <Container component="main" maxWidth="xs">
  <CssBaseline />
  <Box
    sx={{
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      Verify
    </Typography>
    <Box component="form" onSubmit={(e) => { e.preventDefault(); handleTfaCode(); }} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="code"
        label="Code"
        name="code"
        autoComplete="code"
        autoFocus
        value={tfaCode}
        onChange={(e) => setTfaCode(e.target.value)}
      />
     
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Verify
      </Button>
    </Box>
  </Box>
</Container>} else {

  return  <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={(e) => { e.preventDefault(); handleLogin(); }} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
        
    }
    

};

export default LoginPage;
